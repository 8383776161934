import React from "react";
import { useState, useContext } from "react";

import styles from "../auth.module.css";

import { AuthContext } from "../../../context/AuthContext";
import SignUpComponentSidebar from "../SignUpComponentSidebar";
import CircularLoader from "../../CircularLoader/CircularLoader";

const SuccessMetrics = ({ onNext, onBack, handleInputChange, formData }) => {
  const { completeOnboarding } = useContext(AuthContext);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const canContinue =
    formData.current_monthly_meetings &&
    formData.current_lead_pipeline &&
    formData.product_goal;

  async function handleComplete() {
    onNext();
  }

  async function handleBackClick() {
    onBack();
  }

  return (
    <>
      <div className={styles.authentication_input_side}>
        <div className={styles.authentication_input_layout}>
          <div className={styles.input_options}>
            <p className={styles.page_title}>Success Metrics</p>
            <p className={styles.page_subtitle}>Define your success goals</p>
            <p className={styles.login_input_titles}>
              On average, how many meetings are you currently scheduling from
              LinkedIn each month?
            </p>
            <div className={styles.email_input}>
              <input
                onChange={(event) =>
                  handleInputChange(
                    "current_monthly_meetings",
                    event.target.value
                  )
                }
                className={styles.input}
              ></input>
            </div>

            <p className={styles.login_input_titles}>
              On average, how many “leads” are currently in your pipeline? As
              in, people who you think are likely to become clients.
            </p>
            <div className={styles.email_input}>
              <input
                onChange={(event) =>
                  handleInputChange("current_lead_pipeline", event.target.value)
                }
                className={styles.input}
              ></input>
            </div>

            <p className={styles.login_input_titles}>
              What is your main goal of using ReplyAssist? What do you want the
              outcome to be? Of course, we can assume what you want, but it’s
              helpful for you to explicitly tell us so that we know what’s
              important to you.
            </p>
            <div className={styles.email_input}>
              <input
                onChange={(event) =>
                  handleInputChange("product_goal", event.target.value)
                }
                className={styles.input}
              ></input>
            </div>
          </div>
          <>
            {error && <p className={styles.error_text}>{error}</p>}
            {/* {canContinue ? (
              <div onClick={handleComplete} className={styles.page_button}>
                <p className={styles.join_button_text}>Continue</p>
              </div>
            ) : (
              <div className={styles.page_button_inactive}>
                <p className={styles.join_button_text}>Continue</p>
              </div>
            )} */}

            {canContinue ? (
              <>
                {!isLoading ? (
                  <div onClick={handleComplete} className={styles.page_button}>
                    <p className={styles.join_button_text}>Continue</p>
                  </div>
                ) : (
                  <div className={styles.page_button_loading}>
                    <CircularLoader />
                  </div>
                )}
              </>
            ) : (
              <div className={styles.page_button_inactive}>
                <p className={styles.join_button_text}>Continue</p>
              </div>
            )}

            <p className={styles.forgot_password} onClick={handleBackClick}>
              Previous Step
            </p>
          </>
        </div>
      </div>
    </>
  );
};

export default SuccessMetrics;
