import React from "react";
import { useState, useContext } from "react";

import styles from "../auth.module.css";

import { AuthContext } from "../../../context/AuthContext";
import SignUpComponentSidebar from "../SignUpComponentSidebar";
import CircularLoader from "../../CircularLoader/CircularLoader";

const SchedulingLink = ({ onNext, onBack, handleInputChange, formData }) => {
  const { completeOnboarding } = useContext(AuthContext);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const canContinue = formData.schedulingLink;

  async function handleComplete() {
    try {
      setIsLoading(true);
      console.log(formData);
      await completeOnboarding(formData);
      setIsLoading(false);
      onNext();
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  async function handleBackClick() {
    onBack();
  }

  return (
    <>
      <div className={styles.authentication_input_side}>
        <div className={styles.authentication_input_layout}>
          <div className={styles.input_options}>
            <p className={styles.page_title}>Scheduling Link</p>
            <p className={styles.page_subtitle}>
              Enter your Scheduling link URL
            </p>
            <p className={styles.login_input_titles}>Your Scheduling Link</p>
            <div className={styles.email_input}>
              <input
                onChange={(event) =>
                  handleInputChange("schedulingLink", event.target.value)
                }
                className={styles.input}
              ></input>
            </div>
          </div>
          <>
            {error && <p className={styles.error_text}>{error}</p>}

            {canContinue ? (
              <>
                {!isLoading ? (
                  <div onClick={handleComplete} className={styles.page_button}>
                    <p className={styles.join_button_text}>Continue</p>
                  </div>
                ) : (
                  <div className={styles.page_button_loading}>
                    <CircularLoader />
                  </div>
                )}
              </>
            ) : (
              <div className={styles.page_button_inactive}>
                <p className={styles.join_button_text}>Continue</p>
              </div>
            )}

            <p className={styles.forgot_password} onClick={handleBackClick}>
              Previous Step
            </p>
          </>
        </div>
      </div>
    </>
  );
};

export default SchedulingLink;
