import React from "react";
import { useParams } from "react-router-dom";
import NoPaymentSignUp from "../components/AuthComponents/NoPaymentSignUp";

const SignUpPageNoPay = () => {
  const { stepNum } = useParams();
  return (
    <div>
      <NoPaymentSignUp step={parseInt(stepNum)} />
    </div>
  );
};

export default SignUpPageNoPay;
