import React from "react";
import { useState, useContext } from "react";

import styles from "../auth.module.css";

import { AuthContext } from "../../../context/AuthContext";
import SignUpComponentSidebar from "../SignUpComponentSidebar";
import states from "../../option_data/states";
import { countries } from "../../option_data/countries";
import DownArrowIcon from "../../GlobalComponents/DownArrowIcon";
// import countries

const LocationInformation = ({
  onNext,
  onBack,
  handleInputChange,
  formData,
}) => {
  const [isStateDropdownVisible, setIsStateDropdownVisible] = useState(false);
  const [isCountryDropdownVisible, setIsCountryDropdownVisible] =
    useState(false);

  const [error, setError] = useState(null);

  async function handleCreateAccount() {
    onNext();
  }

  async function handleBackClick() {
    onBack();
  }

  const toggleStateDropdown = () =>
    setIsStateDropdownVisible(!isStateDropdownVisible);

  const toggleCountryDropdown = () =>
    setIsCountryDropdownVisible(!isCountryDropdownVisible);

  let canContinue =
    formData.address &&
    formData.city &&
    formData.country &&
    formData.password &&
    formData.zipcode;

  return (
    <>
      <div className={styles.authentication_input_side}>
        <div className={styles.authentication_input_layout}>
          <div className={styles.input_options}>
            <p className={styles.page_title}>Where are you based out of?</p>
            <p className={styles.page_subtitle}>Let us know where you are</p>
            <p className={styles.login_input_titles}>Address Line 1</p>
            <div className={styles.email_input}>
              <input
                onChange={(event) =>
                  handleInputChange("address", event.target.value)
                }
                value={formData.address}
                className={styles.input}
              ></input>
            </div>
            <p className={styles.login_input_titles}>
              Address Line 2 (Optional)
            </p>
            <div className={styles.email_input}>
              <input
                onChange={(event) =>
                  handleInputChange("address_2", event.target.value)
                }
                value={formData.address_2}
                className={styles.input}
              ></input>
            </div>
            <div className={styles.two_column_inputs}>
              <div className={styles.half_input_object}>
                <p className={styles.login_input_titles}>City</p>
                <div className={styles.email_input}>
                  <input
                    onChange={(event) =>
                      handleInputChange("city", event.target.value)
                    }
                    value={formData.city}
                    className={styles.input}
                  ></input>
                </div>
              </div>
              <div className={styles.half_input_object}>
                <p className={styles.login_input_titles}>State</p>
                <div
                  className={styles.dropdown_input}
                  onClick={toggleStateDropdown}
                >
                  <input
                    readOnly
                    onChange={(event) =>
                      handleInputChange("state", event.target.value)
                    }
                    value={formData.state}
                    className={styles.readonly_dropdown_input}
                    placeholder="Select State"
                  ></input>
                  <div
                    className={isStateDropdownVisible ? styles.rotate_180 : ""}
                  >
                    <DownArrowIcon />
                  </div>
                </div>

                {isStateDropdownVisible && (
                  <div className={styles.dropdown_menu}>
                    {states.map((option) => (
                      <div
                        key={option}
                        className={styles.dropdown_option}
                        onClick={() => {
                          handleInputChange("state", option);
                          setIsStateDropdownVisible(false);
                        }}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.two_column_inputs}>
              <div className={styles.half_input_object}>
                <p className={styles.login_input_titles}>Country</p>
                <div
                  className={styles.dropdown_input}
                  onClick={toggleCountryDropdown}
                >
                  <input
                    readOnly
                    onChange={(event) =>
                      handleInputChange("country", event.target.value)
                    }
                    value={formData.country}
                    className={styles.readonly_dropdown_input}
                    placeholder="Select State"
                  ></input>
                  <div
                    className={
                      isCountryDropdownVisible ? styles.rotate_180 : ""
                    }
                  >
                    <DownArrowIcon />
                  </div>
                </div>

                {isCountryDropdownVisible && (
                  <div className={styles.dropdown_menu}>
                    {countries.map((option) => (
                      <div
                        key={option}
                        className={styles.dropdown_option}
                        onClick={() => {
                          handleInputChange("country", option);
                          setIsCountryDropdownVisible(false);
                        }}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className={styles.half_input_object}>
                <p className={styles.login_input_titles}>Zip Code</p>
                <div className={styles.email_input}>
                  <input
                    onChange={(event) =>
                      handleInputChange("zipcode", event.target.value)
                    }
                    value={formData.zipcode}
                    className={styles.input}
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <>
            {error && <p className={styles.error_text}>{error}</p>}
            {canContinue ? (
              <div onClick={handleCreateAccount} className={styles.page_button}>
                <p className={styles.join_button_text}>Continue</p>
              </div>
            ) : (
              <div className={styles.page_button_inactive}>
                <p className={styles.join_button_text}>Continue</p>
              </div>
            )}
            <p className={styles.forgot_password} onClick={handleBackClick}>
              Previous Step
            </p>
          </>
        </div>
      </div>
    </>
  );
};

export default LocationInformation;
