import React from "react";
import { useState, useContext } from "react";

import styles from "./auth.module.css";

import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router";
import CircularLoader from "../CircularLoader/CircularLoader";

const SignInComponent = () => {
  const navigate = useNavigate();
  const { signIn } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState(null);

  function handleSignUp() {
    navigate("/signup");
  }

  function handleForgotPassword() {
    window.location.href =
      "https://dashboard.replyassist.com/auth/password/reset";
  }

  async function handleSubmit() {
    try {
      await signIn(email, password);
      console.log("Login Succeeded: Redirecting to Dashboard");

      const sfToken = localStorage.getItem("SF_Token");
      // console.log(sfToken);
      if (sfToken) {
        const encodedSFToken = encodeURIComponent(sfToken);
        const redirectUrl = `http://dashboard.replyassist.com/auth/thirdPartySignIn?SF_Token=${encodedSFToken}`;

        window.location.href = redirectUrl;
      } else {
        console.error("SF_Token not found in localStorage.");
      }
    } catch (error) {
      console.error("Login failed:", error);
      setError("Invalid Credentials");
    }
  }

  const canContinue = email !== "" && password !== "";

  return (
    <div className={styles.home_components}>
      <div className={styles.home_header_content}>
        <div className={styles.sidebar}>
          <img
            alt="logo"
            src="/ReplyAssistTransparentLogo.png"
            className={styles.logo_icon}
          ></img>
          <p className={styles.signin_page_title}>Welcome Back</p>
          <p className={styles.login_input_titles}>Email Address</p>
          <div className={styles.email_input}>
            <img
              className={styles.auth_input_icon}
              alt="email"
              src="/EmailIcon.png"
            ></img>
            <input
              onChange={(event) => setEmail(event.target.value)}
              className={styles.input}
            ></input>
          </div>
          <p className={styles.login_input_titles}>Password</p>
          <div className={styles.email_input}>
            <img
              className={styles.auth_input_icon}
              alt="password"
              src="/PasswordIcon.png"
            ></img>
            <input
              type="password"
              onChange={(event) => setPassword(event.target.value)}
              className={styles.input}
            ></input>
          </div>
          {error && <p className={styles.error_text}>{error}</p>}
          {canContinue ? (
            <div onClick={handleSubmit} className={styles.page_button}>
              {/* <CircularLoader/> */}
              <p className={styles.join_button_text}>Sign In</p>
            </div>
          ) : (
            <div className={styles.page_button_inactive}>
              <p className={styles.join_button_text}>Sign In</p>
            </div>
          )}
          <div className={styles.prompt_texts}>
            <p className={styles.note}>
              Don't have an account?{" "}
              <span className={styles.clickable_text} onClick={handleSignUp}>
                Sign Up
              </span>
            </p>

            <p className={styles.note}>
              <span
                className={styles.clickable_text}
                onClick={handleForgotPassword}
              >
                Forgot Password?
              </span>
            </p>
          </div>
        </div>
      </div>
      <img
        className={styles.landing_image}
        src="/images/LandingPage.png"
        alt="landing"
      ></img>
    </div>
  );
};

export default SignInComponent;
