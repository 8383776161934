import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";
import App from "./App";

import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import SignUpPageNoPay from "./pages/SignUpPageNoPay";
import LinkCalendly from "./components/AuthComponents/LinkCalendly";
import CalendlyOneTime from "./components/AuthComponents/CalendlyOneTime";

// import PrivacyPolicyPage from "./pages/Landing/PrivacyPolicyPage";
// import TermsOfService from "./pages/Landing/TermsOfService";

import { AuthContext } from "./context/AuthContext";

// function PrivateRoute({ children }) {
//   const { currentUser } = useContext(AuthContext);

//   const isUserAuthenticated = localStorage.getItem("authUser") || currentUser;

//   return isUserAuthenticated ? children : <Navigate to="/signin" />;
// }

function routes() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App childComp={<SignUpPage />} />} />

          {/* <Route
            path="/privacy-policy"
            element={<App childComp={<PrivacyPolicyPage />} />}
          />
          <Route
            path="/terms-of-service"
            element={<App childComp={<TermsOfService />} />}
          /> */}
          <Route path="/signin" element={<App childComp={<SignInPage />} />} />
          <Route path="/signup" element={<App childComp={<SignUpPage />} />} />
          <Route
            path="/signup/direct-link"
            element={<App childComp={<SignUpPageNoPay />} />}
          />
          {/* <Route
            path="/signup/jenny/one-time-link"
            element={<App childComp={<SignUpPageNoPay />} />}
          /> */}
          <Route
            path="/signup/step/:stepNum"
            element={<App childComp={<SignUpPage />} />}
          />
          <Route
            path="/signup/link-calendly"
            element={<App childComp={<LinkCalendly />} />}
          />
          <Route
            path="/signup/complete-account"
            element={<App childComp={<LinkCalendly />} />}
          />
          <Route
            path="/calendly-one-time"
            element={<App childComp={<CalendlyOneTime />} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default routes;
