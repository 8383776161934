import React from "react";
import axios from "axios";
import { useState, useEffect, useContext } from "react";

import styles from "./auth.module.css";
import SignUpComponentSidebar from "./SignUpComponentSidebar";
import { AuthContext } from "../../context/AuthContext";
import CircularLoader from "../CircularLoader/CircularLoader";

const LinkCalendly = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { userId } = useContext(AuthContext);

  useEffect(() => {
    async function fetchAccessToken() {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");

        const userIdFromStorage = localStorage.getItem("userId");
        if (!userIdFromStorage) {
          throw new Error("User ID not found in localStorage");
        }

        const response = await axios.post(
          "https://reply-assist.uc.r.appspot.com/api/calendly/setup",
          {
            code: code,
            userId: userIdFromStorage,
          }
        );

        setLoading(false);

        if (response.data.success) {
          const sfToken = localStorage.getItem("SF_Token");

          if (sfToken) {
            const encodedSFToken = encodeURIComponent(sfToken);
            const redirectUrl = `http://dashboard.replyassist.com/auth/thirdPartySignIn?SF_Token=${encodedSFToken}`;

            window.location.href = redirectUrl;
          } else {
            console.error("SF_Token not found in localStorage.");
          }
        }
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    }

    fetchAccessToken();
  }, []);

  // async function handleSubmit(email, password) {
  //   try {
  //     await signIn(email, password);
  //     console.log("Login Succeeded: Redirecting to Dasboard");
  //     // window.location.href = "https://dashboard.replyassist.com/";
  //   } catch (error) {
  //     console.error("Login failed:", error);
  //   }
  // }

  return (
    <div className={styles.loading_page}>
      <CircularLoader color="rgba(0, 48, 140, 1)" size="10%" />
    </div>
  );
};
export default LinkCalendly;
