import React from "react";
import { useState, useContext, useEffect } from "react";
import axios from "axios";

import styles from "../auth.module.css";

import SignUpComponentSidebar from "../SignUpComponentSidebar";
import { useStripe, useElements } from "@stripe/react-stripe-js";

import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CircularLoader from "../../CircularLoader/CircularLoader";
import CheckoutForm from "../CheckoutForm";
import { toast } from "react-toastify";

import CouponIcon from "../../GlobalComponents/CouponIcon";

const stripePromise = loadStripe(
  "pk_live_51NHy0PKcb2S7BJkwp8N8cEkXJzjTkGt5ArTQ08FSZ562A7LBfnLxFickF4WLqyVOsCum0Tg53lC78MdOAxiL8WhF00C3KHSARW"
);

const DEVELOPMENT_API_KEY = "RA-q7L5m2P3nT8vY4dH0rJ6cX1zG9wA3fU";

const PaymentInformation = ({ onNext, onBack, formData }) => {
  const [clientSecret, setClientSecret] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [activePromoCode, setActivePromoCode] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");
  const [isValidatingPromoCode, setIsValidatingPromoCode] = useState(false);
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [unitAmount, setUnitAmount] = useState(
    formData?.subscription_type?.unit_amount
  );

  useEffect(() => {
    const createSubscription = async () => {
      try {
        const response = await axios.post(
          "https://api.development.replyassist.com/payment/create-subscription",
          {
            customerId: formData.stripe_id,
            priceId: formData?.subscription_type?.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${DEVELOPMENT_API_KEY}`,
            },
          }
        );
        const data = response.data;
        setClientSecret(data.clientSecret);
        setSubscriptionId(data.subscriptionId);
      } catch (error) {
        console.error("Error:", error);
        setError("Failed to create subscription.");
      }
    };

    createSubscription();
  }, []);

  const applyPromoCode = async () => {
    setIsValidatingPromoCode(true);
    try {
      const response = await axios.post(
        "https://api.development.replyassist.com/payment/apply-promo-code",
        {
          customerId: formData.stripe_id,
          priceId: formData?.subscription_type?.id,
          subscriptionId: subscriptionId,
          promoCodeName: promoCode,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${DEVELOPMENT_API_KEY}`,
          },
        }
      );
      const data = response.data;
      console.log(data);
      setClientSecret(data.clientSecret);
      setSubscriptionId(data.subscriptionId);
      setActivePromoCode(promoCode);
      setDiscountedPrice(
        formData?.subscription_type?.unit_amount -
          data.newSubscription.latest_invoice.amount_due
      );
      setUnitAmount(data.newSubscription.latest_invoice.amount_due);
      toast.success("Promo Code Applied!");
    } catch (error) {
      console.error("Error applying promo code:", error);
      toast.error("Error applying promo code");
      setError("Failed to apply promo code.");
    } finally {
      setIsValidatingPromoCode(false);
    }
  };

  const appearance = { theme: "stripe" };
  const options = clientSecret
    ? {
        clientSecret,
        appearance,
        layout: { type: "tabs", defaultCollapsed: true },
      }
    : { appearance, layout: { type: "tabs", defaultCollapsed: true } };

  const [error, setError] = useState(null);

  const canContinue = true;

  async function handleCreateAccount() {
    onNext();
  }

  async function handleBackClick() {
    onBack();
  }

  const formatCurrency = (unitAmount) => {
    return `$${(unitAmount / 100).toFixed(2)}`;
  };

  return (
    <>
      <div className={styles.authentication_input_side}>
        <div className={styles.authentication_input_layout}>
          <div className={styles.input_options}>
            {/* <p className={styles.page_title}>Payment Details</p>
            <p className={styles.page_subtitle}>Enter Payment Information</p> */}
            <div className={styles.subscription_information}>
              <div className={styles.subscription_information_header}>
                <p className={styles.subscription_information_title}>
                  Your Subscription
                </p>
                {activePromoCode ? (
                  <div className={styles.active_promo_code}>
                    <CouponIcon width={12} height={12} />
                    {activePromoCode}
                  </div>
                ) : (
                  <div className={styles.promotion_code_input}>
                    <input
                      className={styles.input}
                      placeholder="Promo Code"
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                    ></input>
                    <p className={styles.apply_button} onClick={applyPromoCode}>
                      {isValidatingPromoCode ? (
                        <CircularLoader
                          color="rgba(0, 48, 140, 1)"
                          size="18px"
                        />
                      ) : (
                        "Apply"
                      )}
                    </p>
                  </div>
                )}
              </div>

              <div className={styles.subscription_type_information}>
                <span className={styles.subscription_information_text_blus}>
                  ReplyAssist Plan
                </span>
                <span className={styles.subscription_information_text}>
                  {formData?.subscription_type?.nickname}
                </span>
              </div>
              <div className={styles.subscription_information_divider}></div>
              {activePromoCode && (
                <div className={styles.subscription_type_information}>
                  <span className={styles.subscription_information_text}>
                    Discount
                  </span>
                  <span
                    className={styles.subscription_information_text_discount}
                  >
                    -{formatCurrency(discountedPrice)}
                  </span>
                </div>
              )}
              {activePromoCode && <div className={styles.spacer_height}></div>}

              <div className={styles.subscription_cost_information}>
                <span className={styles.subscription_information_text}>
                  Total
                </span>
                <span className={styles.subscription_information_text}>
                  {formatCurrency(unitAmount)}
                </span>
              </div>
            </div>
            {!clientSecret ? (
              <div className={styles.loader_layout}>
                <CircularLoader color="rgba(0, 48, 140, 1)" size="10%" />
              </div>
            ) : (
              <div className={styles.checkout_form}>
                <Elements
                  stripe={stripePromise}
                  options={options}
                  key={clientSecret}
                >
                  <CheckoutForm
                    clientSecret={clientSecret}
                    formData={formData}
                    onNext={onNext}
                  />
                </Elements>
              </div>
            )}
          </div>

          <>
            <p className={styles.forgot_password} onClick={handleBackClick}>
              Previous Step
            </p>
          </>
        </div>
      </div>
    </>
  );
};

export default PaymentInformation;
