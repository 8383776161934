import React, { useState } from "react";
import { useContext } from "react";
import styles from "../auth.module.css";
import { AuthContext } from "../../../context/AuthContext";
import CancelIcon from "../../GlobalComponents/CancelIcon";
import SignUpComponentSidebar from "../SignUpComponentSidebar";

const TopicsToAvoid = ({ onNext, onBack, handleInputChange, formData }) => {
  const [selectedOptions, setSelectedOptions] = useState(
    formData.topicsToAvoid || []
  );
  const [newPhrase, setNewPhrase] = useState("");

  const updateFormDataWithOptions = (options) => {
    setSelectedOptions(options);
    handleInputChange("topicsToAvoid", options);
  };

  const handleAddPhrase = () => {
    if (!newPhrase.trim()) return;
    const updatedOptions = [...selectedOptions, newPhrase];
    updateFormDataWithOptions(updatedOptions);
    setNewPhrase("");
  };

  const handleDeletePhrase = (phrase) => {
    const updatedOptions = selectedOptions.filter((item) => item !== phrase);
    updateFormDataWithOptions(updatedOptions);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleAddPhrase();
    }
  };

  return (
    <>
      <div className={styles.authentication_input_side}>
        <div className={styles.authentication_input_layout}>
          <div className={styles.input_options}>
            <p className={styles.page_title}>Topics to Avoid</p>

            <p className={styles.page_subtitle}>
              Set boundaries for chatbot engagement
            </p>

            <div className={styles.input_text_input}>
              <input
                className={styles.input_banned_phrase}
                placeholder="Topic to avoid"
                value={newPhrase}
                onChange={(e) => setNewPhrase(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <div className={styles.add_button} onClick={handleAddPhrase}>
                Add
              </div>
            </div>
            <p className={styles.example_note_gray}>
              For example, if you do not sell Indexed Universal Life Insurance,
              please list Indexed Universal Life Insurance here.
            </p>
            <div className={styles.user_input_topics_layout}>
              {selectedOptions.map((item) => (
                <div className={styles.list_option} key={item}>
                  <p className={styles.list_option_text}>{item}</p>
                  <CancelIcon
                    onClick={() => handleDeletePhrase(item)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ))}
            </div>
          </div>
          <>
            <div className={styles.page_button} onClick={onNext}>
              <p className={styles.join_button_text}>Continue</p>
            </div>
            <p className={styles.forgot_password} onClick={onBack}>
              Previous Step
            </p>
          </>
        </div>
      </div>
    </>
  );
};

export default TopicsToAvoid;
