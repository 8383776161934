import React from "react";
import { useState, useContext } from "react";
import styles from "../auth.module.css";

import { AuthContext } from "../../../context/AuthContext";

import CheckboxField from "../../GlobalComponents/CheckboxField";
import SignUpComponentSidebar from "../SignUpComponentSidebar";
import CustomDropdown from "../CustomDropdown";
import DownArrowIcon from "../../GlobalComponents/DownArrowIcon";

const GeneralInformation = ({
  onNext,
  onBack,
  handleInputChange,
  formData,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [error, setError] = useState(null);

  let canContinue =
    formData.gender &&
    formData.birthday &&
    formData.firmName &&
    formData.aboutMe &&
    formData.isFinancialAdvisor === true;

  async function handleCreateAccount() {
    onNext();
  }

  async function handleBackClick() {
    onBack();
  }

  const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);

  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  return (
    <>
      <div className={styles.authentication_input_side}>
        <div className={styles.authentication_input_layout}>
          <div className={styles.input_options}>
            <p className={styles.page_title}>
              Tell us a little bit about yourself
            </p>
            <p className={styles.page_subtitle}>Personalize your profile</p>
            <div className={styles.two_column_inputs}>
              <div className={styles.half_input_object}>
                <p className={styles.login_input_titles}>Gender</p>
                <div className={styles.dropdown_input} onClick={toggleDropdown}>
                  <input
                    readOnly
                    onChange={(event) =>
                      handleInputChange("gender", event.target.value)
                    }
                    value={formData.gender}
                    className={styles.readonly_dropdown_input}
                    placeholder="Select Gender"
                  ></input>
                  <div className={isDropdownVisible ? styles.rotate_180 : ""}>
                    <DownArrowIcon />
                  </div>
                </div>

                {isDropdownVisible && (
                  <div className={styles.dropdown_menu}>
                    {genderOptions.map((option) => (
                      <div
                        key={option.value}
                        className={styles.dropdown_option}
                        onClick={() => {
                          handleInputChange("gender", option.value);
                          setIsDropdownVisible(false);
                        }}
                      >
                        {option.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className={styles.half_input_object}>
                <p className={styles.login_input_titles}>Birthday</p>
                <div className={styles.email_input}>
                  <input
                    type="date"
                    value={formData.birthday}
                    onChange={(event) =>
                      handleInputChange("birthday", event.target.value)
                    }
                    className={styles.input}
                  ></input>
                </div>
              </div>
            </div>

            <p className={styles.login_input_titles}>
              What Financial Services Firm do you work for
            </p>
            <div className={styles.email_input}>
              <input
                onChange={(event) =>
                  handleInputChange("firmName", event.target.value)
                }
                value={formData.firmName}
                className={styles.input}
              ></input>
            </div>

            <p className={styles.login_input_titles}>
              If a prospect were to ask you why you joined the financial
              services industry, what would you tell them?
            </p>

            <div className={styles.paragraph_input}>
              <textarea
                onChange={(event) =>
                  handleInputChange("aboutMe", event.target.value)
                }
                value={formData.aboutMe}
                className={styles.textarea_input}
              ></textarea>
            </div>
            <CheckboxField
              label="I am a financial advisor/financial services firm"
              checked={formData.isFinancialAdvisor}
              onCheckboxChange={(checked) =>
                handleInputChange("isFinancialAdvisor", checked)
              }
            />
          </div>

          <>
            {error && <p className={styles.error_text}>{error}</p>}
            {canContinue ? (
              <div onClick={handleCreateAccount} className={styles.page_button}>
                <p className={styles.join_button_text}>Continue</p>
              </div>
            ) : (
              <div className={styles.page_button_inactive}>
                <p className={styles.join_button_text}>Continue</p>
              </div>
            )}
            <p className={styles.forgot_password} onClick={handleBackClick}>
              Previous Step
            </p>
          </>
        </div>
      </div>
    </>
  );
};

export default GeneralInformation;
