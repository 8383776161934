import React, { useState, useContext, useEffect } from "react";
import styles from "../auth.module.css";
import SignUpComponentSidebar from "../SignUpComponentSidebar";
import { PaymentContext } from "../../../context/PaymentContext";
import CircularLoader from "../../CircularLoader/CircularLoader";
import CheckmarkIcon from "../../GlobalComponents/CheckIcon";

const ChooseSubscription = ({
  onNext,
  onBack,
  handleInputChange,
  formData,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    formData?.subscription_type?.id
  );
  const { fetchProducts, fetchPrices } = useContext(PaymentContext);

  const [products, setProducts] = useState([]);
  const [pricingOptions, setPricingOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const initial_value_props = [
    "Unlimited Prospecting Campaigns",
    "Unlimited AI-Generated Replies",
    "Automated LinkedIn Outreach (Connection Requests)",
    "Automated Follow Ups with Prospects",
    "Automated / Autodrafted Messaging with Prospects",
    "Automated / Autodrafted Meeting Scheduling with Prospects",
  ];

  const value_props = [
    "Unlimited Prospecting Campaigns",
    "Unlimited AI-Generated Replies",
    "Automated LinkedIn Outreach",
    "Automated Follow Ups",
    "Automated Prospect Messaging",
    "Automated Meeting Scheduling",
  ];

  const updated_features = [
    "Unlimited Prospecting Campaign Types",
    "Automated LinkedIn Outreach (Connection Requests + Messages)",
    "Unlimited AI-Autodrafted Replies",
    "Automated Follow Ups sent to Unengaged Prospects",
    "Autodrafted or Automated Messaging with Prospects",
    "Autodrafted or Automated Meeting Scheduling with Prospects",
  ];

  // useEffect(() => {
  //   const loadProducts = async () => {
  //     const data = await fetchProducts();
  //     console.log(data);
  //     setProducts(data);
  //   };

  //   loadProducts();
  // }, [fetchProducts]);

  const formatCurrency = (unitAmount) => {
    return `$${(unitAmount / 100).toFixed(2)}`;
  };

  useEffect(() => {
    const loadPrices = async () => {
      setIsLoading(true);
      try {
        const pricesData = await fetchPrices("prod_QbjPFyN1FKScs7");
        const sortedPrices = pricesData.data.sort(
          (a, b) => a.unit_amount - b.unit_amount
        );
        setPricingOptions(sortedPrices);
      } catch (error) {
        console.error("Failed to fetch prices", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadPrices();
  }, [fetchPrices]);

  const handleOptionClick = (option) => {
    console.log(option.unit_amount);
    setSelectedOption(option.id);
    handleInputChange("subscription_type", option);
  };

  let canContinue = formData.subscription_type;

  return (
    <>
      <div className={styles.authentication_input_side}>
        <div className={styles.authentication_input_layout}>
          <div className={styles.input_options}>
            <p className={styles.page_title}>Choose Subscription</p>
            <p className={styles.page_subtitle}>
              Pick the plan that best suits you
            </p>
            <div className={styles.payment_option_value_props}>
              {value_props.map((valueProp, index) => (
                <div className={styles.valueProp_layout}>
                  <CheckmarkIcon />
                  <p key={index} className={styles.payment_option_value_prop}>
                    {valueProp}
                  </p>
                </div>
              ))}
            </div>
            {isLoading ? (
              <div className={styles.loader_layout}>
                <CircularLoader color="rgba(0, 48, 140, 1)" size="10%" />
              </div>
            ) : (
              <div className={styles.payment_options_layout}>
                {pricingOptions
                  .filter((option) => option.nickname !== "Test")
                  .map((option) => (
                    <div
                      className={`${styles.payment_option} ${
                        selectedOption === option.id
                          ? styles.payment_option_selected
                          : ""
                      }`}
                      key={option.id}
                      onClick={() => handleOptionClick(option)}
                    >
                      {selectedOption === option.id && (
                        <img
                          src="/check.png"
                          alt="Check"
                          className={styles.check_icon}
                        />
                      )}
                      <p className={styles.payment_option_text}>
                        {option.nickname} • {formatCurrency(option.unit_amount)}
                      </p>
                    </div>
                  ))}
              </div>
            )}
          </div>
          <>
            {canContinue ? (
              <div onClick={onNext} className={styles.page_button}>
                <p className={styles.join_button_text}>Continue</p>
              </div>
            ) : (
              <div className={styles.page_button_inactive}>
                <p className={styles.join_button_text}>Continue</p>
              </div>
            )}
            <p className={styles.forgot_password} onClick={onBack}>
              Previous Step
            </p>
          </>
        </div>
      </div>
    </>
  );
};

export default ChooseSubscription;
