import React from "react";
import styles from "./global.module.css";

const CheckboxField = ({ label, checked, onCheckboxChange }) => {
  const handleCheckboxChange = () => {
    onCheckboxChange(!checked);
  };

  const eula_url = "https://replyassist.com/legal-agreements";

  const privacy_policy_url = "https://replyassist.com/legal-agreements";

  const labelWithLinks = label
    .split(/\b(Terms and Conditions|Privacy Policy)\b/)
    .map((part, index) => {
      if (part === "Terms and Conditions") {
        return (
          <span
            key={index}
            style={{
              color: "rgba(0, 48, 140, 1)",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => window.open(eula_url, "_blank")}
          >
            {part}
          </span>
        );
      } else if (part === "Privacy Policy") {
        return (
          <span
            key={index}
            style={{
              color: "rgba(0, 48, 140, 1)",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => window.open(privacy_policy_url, "_blank")}
          >
            {part}
          </span>
        );
      } else {
        return part;
      }
    });

  return (
    <div className={styles.cntr}>
      <input
        checked={checked}
        type="checkbox"
        className={`${styles.hidden_xs_up} ${styles.cbx}`}
        onChange={handleCheckboxChange}
      />
      <label className={styles.cbx} onClick={handleCheckboxChange}></label>
      <span className={styles.checkbox_label_text}>{labelWithLinks}</span>
    </div>
  );
};

export default CheckboxField;
