import React from "react";

const CouponIcon = ({
  width = 20,
  height = 20,
  color = "rgba(0, 0, 0, 0.8)",
  strokeWidth = 1,
  ...props
}) => (
  <svg
    version="1.1"
    id="fi_721550"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 409.603 409.603"
  >
    <g>
      <g>
        <path
          d="M375.468,0.002h-141.87c-9.385,0-22.502,5.437-29.133,12.063L9.961,206.568c-13.281,13.266-13.281,35.016,0,48.266
			l144.824,144.819c13.251,13.266,34.98,13.266,48.251-0.015L397.54,205.165c6.625-6.625,12.063-19.763,12.063-29.128V34.137
			C409.603,15.367,394.237,0.002,375.468,0.002z M307.197,136.537c-18.852,0-34.135-15.299-34.135-34.135
			c0-18.867,15.283-34.135,34.135-34.135c18.852,0,34.14,15.268,34.14,34.135C341.338,121.238,326.049,136.537,307.197,136.537z"
        ></path>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default CouponIcon;
