import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";

import styles from "./auth.module.css";

import GeneralSignUpNoPay from "./SignUpPages/GeneralSignUpNoPay";
import GeneralInformation from "./SignUpPages/GeneralInformation";
import ChooseSubscription from "./SignUpPages/ChooseSubscription";
import PaymentInformation from "./SignUpPages/PaymentInformation";
import LocationInformation from "./SignUpPages/LocationInformation";
import LicensesCertificates from "./SignUpPages/LicensesCertificates";
import LicensedLocations from "./SignUpPages/LicensedLocations";
import ProductsServices from "./SignUpPages/ProductsServices";
import TopicsToAvoid from "./SignUpPages/TopicsToAvoid";
import HobbiesInterests from "./SignUpPages/HobbiesInterests";
import LinkCalendly from "./SignUpPages/LinkCalendly";
import SuccessMetrics from "./SignUpPages/SuccessMetrics";
import SchedulingLink from "./SignUpPages/SchedulingLink";
import CompleteAccount from "./LinkCalendly";
import End from "./SignUpPages/VERIFYEND";

import SignUpComponentSidebar from "./SignUpComponentSidebar";

import SignUpProgressBar from "./SignUpProgressBar";

const company_stages = [

  { component: GeneralSignUpNoPay, stage: 1 },
  { component: GeneralInformation, stage: 2 },
  { component: LocationInformation, stage: 3 },
  { component: LicensesCertificates, stage: 4 },
  { component: LicensedLocations, stage: 5 },
  { component: ProductsServices, stage: 6 },
  { component: TopicsToAvoid, stage: 7 },
  { component: HobbiesInterests, stage: 8 },
  { component: SuccessMetrics, stage: 9 },
  { component: SchedulingLink, stage: 10 },
  { component: LinkCalendly, stage: 11 },
  // { component: End, stage: 13 },
];

const NoPaymentSignUp = ({ step }) => {
  const [userId, setUserId] = useState(null);
  const [currentStage, setCurrentStage] = useState(1);
  const [formData, setFormData] = useState({});

  const navigate = useNavigate();

  const [showSidebar, setShowSidebar] = useState(window.innerWidth > 600);

  useEffect(() => {
    const handleResize = () => {
      setShowSidebar(window.innerWidth > 600);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    console.log(`Current stage: ${currentStage}`);
    // navigate(`/signup/step/${currentStage}`, { replace: true });
  }, [currentStage, navigate]);

  // useEffect(() => {
  //   if (step && step !== currentStage) {
  //     setCurrentStage(step);
  //   }
  // }, [step]);

  const goToNextStage = () => {
    if (currentStage < company_stages.length) {
      setCurrentStage(currentStage + 1);
    }
  };

  const goBackStage = () => {
    if (currentStage > 1) {
      setCurrentStage(currentStage - 1);
    }
  };

  const CurrentComponent = company_stages.find(
    (stage) => stage.stage === currentStage
  )?.component;

  if (!CurrentComponent) {
    return <div>Something went wrong, please try again later.</div>;
  }

  const updateFormData = (newData) => {
    setFormData({ ...formData, ...newData });
  };

  const handleInputChange = (key, value) => {
    updateFormData({ [key]: value });
  };

  //await signUp(formData);

  return (
    <div className={styles.authentication_page}>
      <div className={styles.authentication_layout}>
        {showSidebar && <SignUpComponentSidebar />}
        <div className={styles.component_side_layout}>
          <SignUpProgressBar
            currentStage={currentStage}
            totalStages={company_stages.length}
          />
          <CurrentComponent
            onNext={goToNextStage}
            onBack={goBackStage}
            handleInputChange={handleInputChange}
            formData={formData}
            userId={userId}
          />
        </div>
      </div>
    </div>
  );
};

export default NoPaymentSignUp;
