import React from "react";
import { useState, useContext, useRef, useEffect } from "react";

import styles from "../auth.module.css";

import base_financial_products from "../../option_data/financial_products";

import AddIcon from "../../GlobalComponents/AddIcon";
import CheckmarkIcon from "../../GlobalComponents/CheckIcon";

import SignUpComponentSidebar from "../SignUpComponentSidebar";

const ProductsServices = ({ onNext, onBack, handleInputChange, formData }) => {
  const [selectedOptions, setSelectedOptions] = useState(
    formData?.productsServices || []
  );

  const [isAddingOption, setIsAddingOption] = useState(false);
  const [newOption, setNewOption] = useState("");

  const [productsServices, setProductsServices] = useState([
    ...base_financial_products,
  ]);

  useEffect(() => {
    setProductsServices([...base_financial_products]);
  }, [base_financial_products]);

  const inputRef = useRef(null);

  useEffect(() => {
    if (isAddingOption) {
      inputRef.current.focus();
    }
  }, [isAddingOption]);

  const handleOptionClick = (option) => {
    let updatedOptions = [];
    if (selectedOptions.includes(option)) {
      updatedOptions = selectedOptions.filter((item) => item !== option);
    } else {
      updatedOptions = [...selectedOptions, option];
    }
    setSelectedOptions(updatedOptions);
    handleInputChange("productsServices", updatedOptions);
  };

  const handleAddOtherClick = () => {
    setIsAddingOption(true);
  };

  const handleNewOptionChange = (event) => {
    setNewOption(event.target.value);
  };

  const handleAddNewOption = () => {
    if (newOption.trim() && !productsServices.includes(newOption.trim())) {
      const updatedOptions = [...selectedOptions, newOption.trim()];
      const updated = [...productsServices, newOption.trim()];
      setSelectedOptions(updatedOptions);
      setProductsServices(updated);
      handleInputChange("productsServices", updatedOptions);
      setIsAddingOption(false);
      setNewOption("");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && isAddingOption) {
      handleAddNewOption();
    }
  };

  return (
    <>
      <div className={styles.authentication_input_side}>
        <div className={styles.authentication_input_layout}>
          <div className={styles.input_options}>
            <p className={styles.page_title}>Products and Services</p>
            <p className={styles.page_subtitle}>
              Select preferred offerings to promote
            </p>
            <div className={styles.page_flexbox}>
              <div className={styles.select_options_layout}>
                {productsServices?.map((item) => (
                  <>
                    {selectedOptions.includes(item) ? (
                      <div
                        className={styles.list_option_selected}
                        key={item}
                        onClick={() => handleOptionClick(item)}
                      >
                        <p className={styles.list_option_text}>{item}</p>
                        <CheckmarkIcon />
                      </div>
                    ) : (
                      <div
                        className={styles.list_option}
                        key={item}
                        onClick={() => handleOptionClick(item)}
                      >
                        <p className={styles.list_option_text}>{item}</p>
                        <AddIcon />
                      </div>
                    )}
                  </>
                ))}
                <>
                  {isAddingOption ? (
                    <div className={styles.list_option_input}>
                      <input
                        ref={inputRef}
                        className={styles.list_option_text_input}
                        placeholder="Type Option..."
                        value={newOption}
                        onChange={handleNewOptionChange}
                        onBlur={handleAddNewOption}
                        onKeyDown={handleKeyPress}
                      />
                      <div className={styles.list_option_input_action_box}>
                        <CheckmarkIcon onClick={handleAddNewOption} />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={styles.list_option}
                      onClick={handleAddOtherClick}
                    >
                      <p className={styles.list_option_text}>
                        Other (please specify)
                      </p>
                      <AddIcon />
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
          <>
            <div className={styles.page_button} onClick={onNext}>
              <p className={styles.join_button_text}>Continue</p>
            </div>
            <p className={styles.forgot_password} onClick={onBack}>
              Previous Step
            </p>
          </>
        </div>
      </div>
    </>
  );
};

export default ProductsServices;
