import React from "react";
import { useState, useContext } from "react";

import styles from "./auth.module.css";

const SignUpComponentSidebar = () => {
  return (
    <div className={styles.authentication_content_side}>
      <p className={styles.authentication_content_text}>
        ReplyAssist Makes Prospecting Easier.<br></br>
        <br></br>
        <strong>Period.</strong>
      </p>
      {/* <img
          className={styles.landing_image}
          src="/images/LandingPage.png"
          alt="landing"
        ></img> */}
    </div>
  );
};

export default SignUpComponentSidebar;
