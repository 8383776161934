import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { auth } from "../services/firebase";
import { db } from "../services/firebase";
import { storage } from "../services/firebase";

import {
  query,
  orderBy,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  where,
  writeBatch,
  onSnapshot,
  limit,
  addDoc,
} from "firebase/firestore";

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);

  const DEVELOPMENT_API_KEY = "RA-q7L5m2P3nT8vY4dH0rJ6cX1zG9wA3fU";

  async function signIn(email, password) {
    try {
      const endpoint = "https://api.development.replyassist.com/sign-in";
      const response = await axios.post(
        endpoint,
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${DEVELOPMENT_API_KEY}`,
          },
        }
      );

      console.log("Login successful!", response.data.userDetails);

      localStorage.setItem(
        "SF_Token",
        JSON.stringify({
          email: response.data.userDetails.email,
          expiresIn: response.data.userDetails.expiresIn,
          accessToken: response.data.userDetails.accessToken,
          localId: response.data.userDetails.localId,
          refreshToken: response.data.userDetails.refreshToken,
        })
      );

      setError(null);
    } catch (error) {
      console.error(
        "Error during sign-in:",
        error.response?.data?.message || error.message
      );
      setError(
        error.response?.data?.message || "An error occurred during login."
      );
      throw error;
    }
  }

  const databaseRegister = async (email, firstName, lastName) => {
    try {
      const accountsRef = collection(db, "RegisteredUsers");
      const existingUsersQuery = query(
        accountsRef,
        where("email", "==", email)
      );
      const querySnapshot = await getDocs(existingUsersQuery);

      // await addBrevoContact(email, firstName, lastName, "", "", 14);

      let userDocRef;

      if (!querySnapshot.empty) {
        console.log("An account with this email already exists.");

        userDocRef = querySnapshot.docs[0].ref;
      } else {
        userDocRef = doc(accountsRef);

        await setDoc(userDocRef, {
          uid: userDocRef.id,
          firstName: firstName,
          lastName: lastName,
          email: email,
          timestamp: Date.now(),
        });

        console.log(
          "User data added successfully to RegisteredUsers collection.",
          userDocRef.id
        );
      }

      const stripeCustomer = await createOrFetchStripeCustomer(
        email,
        firstName,
        lastName
      );
      if (stripeCustomer && stripeCustomer.customerId) {
        console.log("Stripe customer created or fetched successfully.");

        await updateDoc(userDocRef, {
          stripe_id: stripeCustomer.customerId,
        });

        console.log("Updated user document with Stripe customer ID.");
      }

      return {
        success: true,
        uid: userDocRef.id,
        stripe_id: stripeCustomer?.customerId || null,
      };
    } catch (error) {
      console.error(
        "Error handling user registration and Stripe customer creation:",
        error
      );
      throw error;
    }
  };

  const createOrFetchStripeCustomer = async (email, firstName, lastName) => {
    try {
      const endpoint =
        "https://api.development.replyassist.com/payment/create-or-fetch-customer";

      const response = await axios.post(
        endpoint,
        {
          email,
          firstName,
          lastName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${DEVELOPMENT_API_KEY}`,
          },
        }
      );

      console.log("Customer data:", response.data);

      setError(null);
      return response.data;
    } catch (error) {
      console.error(
        "Error creating or fetching customer:",
        error.response?.data?.message || error.message
      );
      setError(
        error.response?.data?.message ||
          "An error occurred while processing customer data."
      );
      return null;
    }
  };

  async function signUp(formData) {
    try {
      const responseData = await salesflowAuthenticate(
        formData.email,
        formData.password
      );

      await addBrevoContact(
        formData.email,
        formData.firstName,
        formData.lastName,
        formData.firmName,
        "",
        11,
        formData.birthday
      );

      console.log("Login successful!", responseData);
      console.log("Login successful!", responseData.data);

      const staging = false;

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      const user = userCredential.user;

      localStorage.setItem("userId", responseData.data.salesflow_id);
      setUserId(responseData.data.salesflow_id);

      // const salesflow_id = responseData.data.salesflow_id;

      await setDoc(doc(db, "Users", responseData.data.salesflow_id), {
        uid: user.uid,
        id: user.uid,
        salesflow_id: responseData.data.salesflow_id,
        stripeId: formData.stripe_id || "N/A",
        email: formData.email,
        userFirstName: formData.firstName,
        lastName: formData.lastName,
        // userGender: formData.gender,
        // aboutMe: formData.aboutMe,
        // address: formData.address,
        // zipcode: formData.zipcode,
        // birthday: formData.birthday,
        // country: formData.country,

        email: formData.email,
        subscription_type: formData.subscription_type?.lookup_key || "N/A",
        subscription_id: formData.subscription_type?.id || "N/A",
        onboarded: false,
        staging: staging,
        account_created_timestamp: Date.now(),
      });

      localStorage.setItem(
        "SF_Token",
        JSON.stringify({
          email: responseData.data.email,
          expiresIn: responseData.data.expiresIn,
          accessToken: responseData.data.accessToken,
          localId: responseData.data.localId,
          refreshToken: responseData.data.refreshToken,
        })
      );

      console.log("Registration successful!", responseData.data);
    } catch (error) {
      console.error(
        "Error during registration:",
        error.responseData?.data?.message || error.message
      );
      throw error;
    }
  }

  async function signUpNoPay(formData) {
    try {
      const responseData = await salesflowAuthenticate(
        formData.email,
        formData.password
      );
      console.log("Login successful!", responseData);
      console.log("Login successful!", responseData.data);

      const staging = false;

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      const user = userCredential.user;

      localStorage.setItem("userId", user.uid);
      setUserId(user.uid);

      // const salesflow_id = responseData.data.salesflow_id;

      await setDoc(doc(db, "Users", responseData.data.salesflow_id), {
        uid: user.uid,
        id: user.uid,
        salesflow_id: responseData.data.salesflow_id,
        stripeId: formData.stripe_id || "N/A",
        email: formData.email,
        userFirstName: formData.firstName,
        lastName: formData.lastName,
        // userGender: formData.gender,
        // aboutMe: formData.aboutMe,
        // address: formData.address,
        // zipcode: formData.zipcode,
        // birthday: formData.birthday,
        // country: formData.country,
        // schedulingLink: formData.schedulingLink,
        email: formData.email,
        subscription_type: formData.subscription_type?.lookup_key || "N/A",
        subscription_id: formData.subscription_type?.id || "N/A",
        onboarded: false,
        staging: staging,
        account_created_timestamp: Date.now(),
      });

      localStorage.setItem(
        "SF_Token",
        JSON.stringify({
          email: responseData.data.email,
          expiresIn: responseData.data.expiresIn,
          accessToken: responseData.data.accessToken,
          localId: responseData.data.localId,
          refreshToken: responseData.data.refreshToken,
        })
      );

      console.log("Registration successful!", responseData.data);
    } catch (error) {
      console.error(
        "Error during registration:",
        error.responseData?.data?.message || error.message
      );
      throw error;
    }
  }

  async function salesflowAuthenticate(email, password) {
    const endpoint = "https://api.development.replyassist.com/sign-up";
    const domainHostName = "dashboard.replyassist.com";

    try {
      const response = await axios.post(
        endpoint,
        {
          email: email,
          password: password,
          domainHostName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${DEVELOPMENT_API_KEY}`,
          },
        }
      );

      console.log("Login successful!", response.data.userDetails);
      return response;
    } catch (error) {
      console.error(
        "Login failed!",
        error.response ? error.response.data : error
      );
      throw error;
    }
  }

  async function completeOnboarding(userData) {
    try {
      await updateDoc(doc(db, "Users", userId), {
        userLicensesAndCertifications: userData?.licensesCertificates || [],
        userLicensedLocations: userData?.licensedLocations || [],
        userProductsAndServicesToPromote: userData?.productsServices || [],
        userProductsAndServicesToAvoid: userData?.topicsToAvoid || [],
        userHobbiesAndInterests: userData?.hobbiesAndInterests || [],
        current_monthly_meetings: userData?.current_monthly_meetings || "",
        current_lead_pipeline: userData?.current_lead_pipeline || "",
        product_goal: userData?.product_goal || "",
        onboarded: true,

        schedulingLink: userData.schedulingLink,
        userGender: userData?.gender || "",
        aboutMe: userData?.aboutMe || "",
        address: userData?.address || "",
        zipcode: userData?.zipcode || "",
        birthday: userData?.birthday || "",
        country: userData?.country || "",
        city: userData?.city || "",
        state: userData?.state || "",
      });

      console.log("Onboarding completed successfully!");
    } catch (error) {
      console.error("Error during onboarding completion:", error.message);

      throw error;
    }
  }

  function redirectToCalendlyAuth() {
    const clientId = "YaFEB8yIi1zyRx_Rdlew7csrhSqjWOYzed1bKu64sa4";
    const redirectUri = "https://auth.replyassist.com/signup/link-calendly";
    const url = `https://auth.calendly.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`;

    window.location.href = url;
  }

  //

  const addBrevoContact = async (
    email,
    firstName,
    lastName,
    company,
    number,
    listId,
    birthday
  ) => {
    try {
      const requestData = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        company: company,
        number: number,
        list: listId,
        birthday: birthday,
      };

      const firebaseFunctionUrl =
        "https://us-central1-reply-assist.cloudfunctions.net/brevo/addContact";

      const response = await axios.post(firebaseFunctionUrl, requestData);

      if (response.status === 200) {
        console.log("Firebase Function Response:", response.data);
      } else {
        console.error(
          `Error calling Firebase Function. Status Code: ${
            response.status
          }, Response: ${JSON.stringify(response.data)}`
        );
      }
    } catch (error) {
      if (error.response) {
        console.error(
          `Error calling Firebase Function. Status Code: ${
            error.response.status
          }, Response: ${JSON.stringify(error.response.data)}`
        );
      } else if (error.request) {
        console.error(
          "No response received from Firebase Function.",
          error.request
        );
      } else {
        console.error("Error calling Firebase Function:", error.message);
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signUp,
        databaseRegister,
        completeOnboarding,
        redirectToCalendlyAuth,
        salesflowAuthenticate,
        signUpNoPay,
        addBrevoContact,
        userId,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
