import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "../auth.module.css";
import baseFinancialLicenses from "../../option_data/financial_licenses";
import AddIcon from "../../GlobalComponents/AddIcon";
import CheckmarkIcon from "../../GlobalComponents/CheckIcon";

const LicensesCertificates = ({
  onNext,
  onBack,
  handleInputChange,
  formData,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(
    formData?.licensesCertificates || []
  );
  const [isAddingOption, setIsAddingOption] = useState(false);
  const [newOption, setNewOption] = useState("");

  const [financialLicenses, setFinancialLicenses] = useState([
    ...baseFinancialLicenses,
  ]);

  useEffect(() => {
    setFinancialLicenses([...baseFinancialLicenses]);
  }, [baseFinancialLicenses]);

  const inputRef = useRef(null);

  useEffect(() => {
    if (isAddingOption) {
      inputRef.current.focus();
    }
  }, [isAddingOption]);

  const handleOptionClick = (option) => {
    let updatedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];
    setSelectedOptions(updatedOptions);
    handleInputChange("licensesCertificates", updatedOptions);
  };

  const handleAddOtherClick = () => {
    setIsAddingOption(true);
  };

  const handleNewOptionChange = (event) => {
    setNewOption(event.target.value);
  };

  const handleAddNewOption = () => {
    if (newOption.trim() && !financialLicenses.includes(newOption.trim())) {
      const updatedOptions = [...selectedOptions, newOption.trim()];
      const updatedFinancialLicenses = [...financialLicenses, newOption.trim()];
      setSelectedOptions(updatedOptions);
      setFinancialLicenses(updatedFinancialLicenses);
      handleInputChange("licensesCertificates", updatedOptions);
      setIsAddingOption(false);
      setNewOption("");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && isAddingOption) {
      handleAddNewOption();
    }
  };

  return (
    <>
      <div className={styles.authentication_input_side}>
        <div className={styles.authentication_input_layout}>
          <div className={styles.input_options}>
            <p className={styles.page_title}>Licenses and Certificates</p>
            <p className={styles.page_subtitle}>
              Share your professional qualifications
            </p>
            <div className={styles.page_flexbox}>
              <div className={styles.select_options_layout}>
                {financialLicenses.map((item, index) => (
                  <>
                    {selectedOptions.includes(item) ? (
                      <div
                        className={styles.list_option_selected}
                        key={item}
                        onClick={() => handleOptionClick(item)}
                      >
                        <p className={styles.list_option_text}>{item}</p>
                        <CheckmarkIcon />
                      </div>
                    ) : (
                      <div
                        className={styles.list_option}
                        key={item}
                        onClick={() => handleOptionClick(item)}
                      >
                        <p className={styles.list_option_text}>{item}</p>
                        <AddIcon />
                      </div>
                    )}
                  </>
                ))}
                <>
                  {isAddingOption ? (
                    <div className={styles.list_option_input}>
                      <input
                        ref={inputRef}
                        className={styles.list_option_text_input}
                        placeholder="Type Option..."
                        value={newOption}
                        onChange={handleNewOptionChange}
                        onBlur={handleAddNewOption}
                        onKeyDown={handleKeyPress}
                      />
                      <div className={styles.list_option_input_action_box}>
                        <CheckmarkIcon onClick={handleAddNewOption} />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={styles.list_option}
                      onClick={handleAddOtherClick}
                    >
                      <p className={styles.list_option_text}>
                        Other (please specify)
                      </p>
                      <AddIcon />
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
          <>
            <div className={styles.page_button} onClick={onNext}>
              <p className={styles.join_button_text}>Continue</p>
            </div>
            <p className={styles.forgot_password} onClick={onBack}>
              Previous Step
            </p>
          </>
        </div>
      </div>
    </>
  );
};

export default LicensesCertificates;
