const interests = [
  "Sports",
  "Music",
  "Yoga",
  "Politics",
  "Green Energy",
  "Reading",
  "Cooking",
  "Traveling",
  "Photography",
  "Gardening",
  "Writing",
  "Painting",
  "Drawing",
  "Hiking",
  "Camping",
  "Crossfit",
  "Dancing",
  "Skiing",
  "Snowboarding",
  "Surfing",
  "Scuba Diving",
  "Fishing",
  "Birdwatching",
  "Cycling",
  "Running",
  "Gaming",
  "Film",
  "Theater",
  "Fashion",
  "DIY Projects",
  "Collecting",
  "Volunteering",
  "Crafts",
  "Meditation",
  "Chess",
  "Board Games",
  "Singing",
  "Cooking",
  "Baking",
  "Wine Tasting",
  "Beer Brewing",
  "Calligraphy",
  "Stargazing",
  "Astrophotography",
  "Model Building",
  "Antiquing",
  "Genealogy",
  "Language Learning",
  "Exercising",
  "Beekeeping",
  "Woodworking",
];

export default interests;
