import React from "react";
import { useState, useContext } from "react";

import styles from "../auth.module.css";

import { AuthContext } from "../../../context/AuthContext";
import SignUpComponentSidebar from "../SignUpComponentSidebar";

const LinkCalendlyOneTime = ({
  onNext,
  onBack,
  handleInputChange,
  formData,
}) => {
  const { databaseRegister, redirectToCalendlyAuth, salesflowAuthenticate } =
    useContext(AuthContext);

  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");

  // const canContinue = formData?.schedulingLink;

  async function handleLogin() {
    const sfToken = localStorage.getItem("SF_Token");

    if (sfToken) {
      const encodedSFToken = encodeURIComponent(sfToken);
      const redirectUrl = `http://dashboard.replyassist.com/auth/thirdPartySignIn?SF_Token=${encodedSFToken}`;

      window.location.href = redirectUrl;
    } else {
      console.error("SF_Token not found in localStorage.");
    }
  }

  async function handleBackClick() {
    onBack();
  }

  return (
    <>
      <div className={styles.authentication_input_side}>
        <div className={styles.authentication_input_layout}>
          <div className={styles.calendly_spacer}></div>
          <div className={styles.input_options}>
            <p className={styles.page_title}>Link Calendly</p>
            <p className={styles.page_subtitle}>
              Integrate your scheduling link
            </p>

            <div
              className={styles.link_calendly_button}
              onClick={redirectToCalendlyAuth}
            >
              Connect Calendly
            </div>
            {/* <p className={styles.skip_step} onClick={handleLogin}>
              Skip Step
            </p> */}
          </div>

          <>
            {/* <p className={styles.forgot_password} onClick={handleBackClick}>
              Previous Step
            </p> */}
          </>
        </div>
      </div>
    </>
  );
};

export default LinkCalendlyOneTime;
