import React from "react";
import { useState, useContext } from "react";

import styles from "../auth.module.css";

import { AuthContext } from "../../../context/AuthContext";
import CheckboxField from "../../GlobalComponents/CheckboxField";
import CircularLoader from "../../CircularLoader/CircularLoader";
import { useNavigate } from "react-router";

import SignUpComponentSidebar from "../SignUpComponentSidebar";

const GeneralSignUpNoPay = ({ onNext, handleInputChange, formData }) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    databaseRegister,
    redirectToCalendlyAuth,
    salesflowAuthenticate,
    signUp,
  } = useContext(AuthContext);

  let canContinue =
    formData.firstName &&
    formData.lastName &&
    formData.email &&
    formData.password &&
    formData.password === formData.rePassword &&
    formData.privacyPolicyChecked === true &&
    formData.eulaChecked === true;

  async function handleCreateAccount() {
    if (!canContinue) {
      setError("Please fill all fields correctly.");
      return;
    }

    setIsLoading(true);
    // await salesflowAuthenticate(formData.email, formData.password);
    try {
      await signUp(formData);

      onNext();

      setIsLoading(false);
    } catch (signUpError) {
      console.error("Signup error:", signUpError);
      if (signUpError.code === "auth/email-already-in-use") {
        setError("Email already in use.");
      } else {
        setError(
          signUpError.message || "An error occurred during the sign-up process."
        );
      }
    }
    setIsLoading(false);
  }

  const navigate = useNavigate();
  function handleSignIn() {
    navigate("/signin");
  }

  return (
    <>
      <div className={styles.authentication_input_side}>
        <div className={styles.authentication_input_layout}>
          <div className={styles.input_options}>
            <p className={styles.page_title}>Create your account</p>
            <p className={styles.page_subtitle}>Register for ReplyAssist</p>
            <div className={styles.two_column_inputs}>
              <div className={styles.half_input_object}>
                <p className={styles.login_input_titles}>First Name</p>
                <div className={styles.email_input}>
                  <input
                    onChange={(event) =>
                      handleInputChange("firstName", event.target.value)
                    }
                    value={formData.firstName}
                    className={styles.input}
                  ></input>
                </div>
              </div>
              <div className={styles.half_input_object}>
                <p className={styles.login_input_titles}>Last Name</p>
                <div className={styles.email_input}>
                  <input
                    onChange={(event) =>
                      handleInputChange("lastName", event.target.value)
                    }
                    value={formData.lastName}
                    className={styles.input}
                  ></input>
                </div>
              </div>
            </div>
            <p className={styles.login_input_titles}>Email Address</p>
            <div className={styles.email_input}>
              <input
                onChange={(event) =>
                  handleInputChange("email", event.target.value)
                }
                value={formData.email}
                className={styles.input}
              ></input>
            </div>
            <p className={styles.login_input_titles}>Password</p>
            <div className={styles.email_input}>
              <input
                type="password"
                value={formData.password}
                onChange={(event) =>
                  handleInputChange("password", event.target.value)
                }
                className={styles.input}
              ></input>
            </div>
            <p className={styles.login_input_titles}>Confirm Password</p>
            <div className={styles.email_input}>
              <input
                type="password"
                value={formData.rePassword}
                onChange={(event) =>
                  handleInputChange("rePassword", event.target.value)
                }
                className={styles.input}
              ></input>
            </div>
            <div className={styles.checkbox_labels}>
              <CheckboxField
                label="I agree to the Privacy Policy"
                checked={formData.privacyPolicyChecked}
                onCheckboxChange={(checked) =>
                  handleInputChange("privacyPolicyChecked", checked)
                }
              />
              <CheckboxField
                label="I agree to the Terms and Conditions"
                checked={formData.eulaChecked}
                onCheckboxChange={(checked) =>
                  handleInputChange("eulaChecked", checked)
                }
              />
            </div>
          </div>
          <>
            {error && <p className={styles.error_text}>{error}</p>}
            {canContinue ? (
              <>
                {!isLoading ? (
                  <div
                    onClick={handleCreateAccount}
                    className={styles.page_button}
                  >
                    <p className={styles.join_button_text}>Register Account</p>
                  </div>
                ) : (
                  <div className={styles.page_button_loading}>
                    <CircularLoader />
                  </div>
                )}
              </>
            ) : (
              <div className={styles.page_button_inactive}>
                <p className={styles.join_button_text}>Register Account</p>
              </div>
            )}
            <p className={styles.note}>
              Already have an account?{" "}
              <span className={styles.clickable_text} onClick={handleSignIn}>
                Sign In
              </span>
              {/* <span
                className={styles.test_clickable_text}
                onClick={redirectToCalendlyAuth}
              >
                Test Calendly
              </span> */}
            </p>
          </>
        </div>
      </div>
    </>
  );
};

export default GeneralSignUpNoPay;
