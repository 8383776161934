import React from "react";
import { useState, useContext } from "react";

import styles from "../auth.module.css";

import states from "../../option_data/states";
import canadian_provinces from "../../option_data/canadian_provinces";

import AddIcon from "../../GlobalComponents/AddIcon";
import CheckmarkIcon from "../../GlobalComponents/CheckIcon";
import SignUpComponentSidebar from "../SignUpComponentSidebar";

import SignUpProgressBar from "../SignUpProgressBar";

const LicensedLocations = ({ onNext, onBack, handleInputChange, formData }) => {
  const [selectedOptions, setSelectedOptions] = useState(
    formData?.licensedLocations || []
  );

  const handleOptionClick = (option) => {
    let updatedOptions = [];
    if (selectedOptions.includes(option)) {
      updatedOptions = selectedOptions.filter((item) => item !== option);
    } else {
      updatedOptions = [...selectedOptions, option];
    }
    setSelectedOptions(updatedOptions);
    handleInputChange("licensedLocations", updatedOptions);
  };

  const allLocations = [...states, ...canadian_provinces];

  return (
    <>
      <div className={styles.authentication_input_side}>
        <div className={styles.authentication_input_layout}>
          <div className={styles.input_options}>
            <p className={styles.page_title}>Licensed Locations</p>
            <p className={styles.page_subtitle}>
              Specify authorized business territories
            </p>
            <div className={styles.page_flexbox}>
              <div className={styles.select_options_layout}>
                {allLocations?.map((item) => (
                  <>
                    {selectedOptions.includes(item) ? (
                      <div
                        className={styles.list_option_selected}
                        key={item}
                        onClick={() => handleOptionClick(item)}
                      >
                        <p className={styles.list_option_text}>{item}</p>
                        <CheckmarkIcon />
                      </div>
                    ) : (
                      <div
                        className={styles.list_option}
                        key={item}
                        onClick={() => handleOptionClick(item)}
                      >
                        <p className={styles.list_option_text}>{item}</p>
                        <AddIcon />
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
          <>
            <div className={styles.page_button} onClick={onNext}>
              <p className={styles.join_button_text}>Continue</p>
            </div>
            <p className={styles.forgot_password} onClick={onBack}>
              Previous Step
            </p>
          </>
        </div>
      </div>
    </>
  );
};

export default LicensedLocations;
