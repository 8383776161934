const financial_products = [
  "Whole life insurance",
  "Term insurance",
  "Index Universal Life",
  "Long-term care insurance",
  "Health insurance",
  "Investments",
  "Roth IRA / Traditional IRA",
  "401k",
  "403b",
  "Fee-based planning (Managed accounts)",
  "Mutual funds",
  "ETFs",
  "Stocks",
  "Bonds",
  "Commodities",
  "Real Estate",
  "Real Estate Investment Trusts",
  "Certificate of Deposits (CDs)",
  "Money market funds",
  "Savings",
  "Health savings accounts",
  "Budgeting",
  "Estate planning",
  "Trusts",
  "Variable Annuities",
  "Fixed Annuities",
  "Indexed Annuities",
  "Financial Advice",
  "Financial Planning Services",
];

export default financial_products;
