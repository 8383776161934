const financial_licenses = [
  "Life insurance",
  "Health insurance",
  "Disability insurance",
  "Long-term care certificate",
  "SIE",
  "Series 6",
  "Series 7",
  "Series 63",
  "Series 65",
  "Series 66",
  "I am a Fiduciary",
];

export default financial_licenses;
