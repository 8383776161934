import React from "react";
import SignInComponent from "../components/AuthComponents/SignInComponent";

const SignInPage = () => {
  return (
    <>
      <SignInComponent />
    </>
  );
};

export default SignInPage;
