import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";

import styles from "./auth.module.css";

import LinkCalendlyOneTime from "./SignUpPages/LinkCalendlyOneTime";

import SignUpComponentSidebar from "./SignUpComponentSidebar";

import SignUpProgressBar from "./SignUpProgressBar";

const CalendlyOneTime = ({ step }) => {
  const [userId, setUserId] = useState(null);
  const [currentStage, setCurrentStage] = useState(1);
  const [formData, setFormData] = useState({});

  const [showSidebar, setShowSidebar] = useState(window.innerWidth > 600);

  const updateFormData = (newData) => {
    setFormData({ ...formData, ...newData });
  };

  const handleInputChange = (key, value) => {
    updateFormData({ [key]: value });
  };

  return (
    <div className={styles.authentication_page}>
      <div className={styles.authentication_layout}>
        {showSidebar && <SignUpComponentSidebar />}
        <div className={styles.component_side_layout}>
          <LinkCalendlyOneTime />
        </div>
      </div>
    </div>
  );
};

export default CalendlyOneTime;
