import React, { useEffect, useState } from "react";
import styles from "./auth.module.css";

const SignUpProgressBar = ({ currentStage, totalStages }) => {
  const [displayPercentage, setDisplayPercentage] = useState(0);
  const targetPercentage = Math.round((currentStage / totalStages) * 100);

  useEffect(() => {
    const diff = targetPercentage - displayPercentage;
    if (diff !== 0) {
      const delta = diff / 10;
      const timer = setInterval(() => {
        setDisplayPercentage((prev) => {
          const updated = prev + delta;
          // Stop updating when the target is reached
          if (
            (delta > 0 && updated >= targetPercentage) ||
            (delta < 0 && updated <= targetPercentage)
          ) {
            clearInterval(timer);
            return targetPercentage;
          }
          return updated;
        });
      }, 20);
      return () => clearInterval(timer);
    }
  }, [targetPercentage]);

  return (
    <div className={styles.progress_layout}>
      <div
        className={styles.progress_bar}
        style={{ width: `${targetPercentage}%` }}
      ></div>
      <div className={styles.progress_circle}>
        <p className={styles.progress_percent}>
          {Math.round(displayPercentage)}%
        </p>
      </div>
    </div>
  );
};

export default SignUpProgressBar;
